import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import ApiResultExpertiseResponse from "@/api/rex/types/reports/apiResultExpertiseResponse";
import httpService from "@/api/client/httpService";

export class ReportsController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getResultExpertise = async (id: string, token: string) => {
		const resultExpertise = await httpService.get<ApiResultExpertiseResponse>(urls.rex.advice.resultExpertises.replace(urlTemplateParts.id, id), {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		return plainToInstance(ApiResultExpertiseResponse, resultExpertise);
	}
}
