import { BankAccountApplicationDocumentTypeEnum } from "@/store/bar/types/BankAccountApplicationDocumentTypeEnum";
import DocumentStatusType from "@/store/bar/types/documentStatusType";

export default class ApiDocumentResponse {
	bankAccountApplicationId: string;
	accountId: string;
	storedFileId: string;
	type: BankAccountApplicationDocumentTypeEnum;
	status: DocumentStatusType;
	explanation: string;
	createdAt: string;
	createdBy: string;
	documentName: string;
	
	constructor(
		bankAccountApplicationId: string = "",
		accountId: string = "",
		storedFileId: string = "",
		type: BankAccountApplicationDocumentTypeEnum = BankAccountApplicationDocumentTypeEnum.UNKNOWN,
		status: DocumentStatusType = DocumentStatusType.UNKNOWN,
		explanation: string = "",
		createdAt: string = "",
		createdBy: string = "",
		documentName: string = ""
	)
	{
		this.bankAccountApplicationId = bankAccountApplicationId;
		this.accountId = accountId;
		this.storedFileId = storedFileId;
		this.type = type;
		this.status = status;
		this.explanation = explanation;
		this.createdAt = createdAt;
		this.createdBy = createdBy;
		this.documentName = documentName;
	}
}
