export default class ApiBankAccountApplicationLegalDepartmentInfo {
	legalDepartmentStaffEmployeeId: string;
	isAccepted: boolean;
	isDeclined: boolean;
	
	constructor(
		legalDepartmentStaffEmployeeId: string = "",
		isAccepted: boolean = false,
		isDeclined: boolean = false
	)
	{
		this.legalDepartmentStaffEmployeeId = legalDepartmentStaffEmployeeId;
		this.isAccepted = isAccepted;
		this.isDeclined = isDeclined;
	}
}
