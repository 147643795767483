export default class ApiBankAccountApplicationMessage {
	relatedMessageId: string;
	messageText: string;
	employeeId: string;
	toEmployees: string[];
	
	constructor(
		relatedMessageId: string = "",
		messageText: string = "",
		employeeId: string = "",
		toEmployees: string[] = []
	)
	{
		this.relatedMessageId = relatedMessageId;
		this.messageText = messageText;
		this.employeeId = employeeId;
		this.toEmployees = toEmployees;
	}
}
