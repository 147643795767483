import { BankAccountStatusTypeEnum } from "@/store/bar/types/BankAccountStatusTypeEnum";
import ApiBankAccountData from "@/api/bar/types/apiBankAccountData";
import { BankAccountMonitoringSystemConnectionStatusTypeEnum } from "@/store/bar/types/BankAccountMonitoringSystemConnectionStatusTypeEnum";
import { ApiProjectInfo } from "@/api/bar/types/apiProjectInfo";

export default class ApiBankAccountInfo {
	constructor(
		public account: ApiBankAccountData = new ApiBankAccountData(),
		public counterpartyShortName: string = "",
		public monitoringSystemConnectionStatus: BankAccountMonitoringSystemConnectionStatusTypeEnum = BankAccountMonitoringSystemConnectionStatusTypeEnum.NOT_CONNECTED,
		public status: BankAccountStatusTypeEnum = BankAccountStatusTypeEnum.UNKNOWN,
		public loanApplicationNumber: number = 0,
		public bankId: string = "",
		public bankBranchId: string = "",
		public controlResponsibleEmployeeId: string = "",
		public controlDate: string = "",
		public startAcceptDate: string = "",
		public endAcceptDate: string = "",
		public projectInfos: ApiProjectInfo[] = []
	)
	{
	}
}
