import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";

export default class ApiBankAccountData {
	id: string;
	accountType: BankAccountTypeEnum;
	accountNumber: string;
	currencyId: string;
	openedAt: string;

	constructor(
		id: string = "",
		accountType: BankAccountTypeEnum = BankAccountTypeEnum.UNKNOWN,
		accountNumber: string = "",
		currencyId: string = "",
		openedAt: string = ""
	)
	{
		this.id = id;
		this.accountType = accountType;
		this.accountNumber = accountNumber;
		this.currencyId = currencyId;
		this.openedAt = openedAt;
	}
}
