<template>
	<div class="toolbar">
		<div class="toolbar--info">
			<v-menu bottom
					nudge-bottom="20"
					max-width="250"
					offset-y
					content-class="elevation-5 menu-transition">
				<template v-slot:activator="{ on, attrs }">
					<v-btn 
						   class="text-none profile-btn text-left justify-end user-menu pr-2 pl-5 text-subtitle-2 white--text"
						   style="border: none"
						   v-ripple="false"
						   v-bind="attrs"
						   v-on="on" text outlined>
						<span class="text-truncate">
							{{name}}
						</span>
						<frp-icon class="ml-2" src="ico_bod-chevron-down" :color="colors.grey.lighten4"></frp-icon>
						<frp-icon class="ml-2" src="ico_help" :color="colors.warning.base"></frp-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="logout">
						<v-icon :color="colors.primary.lighten1">mdi-logout</v-icon>
						<v-list-item-title class="ml-2">{{ $t("buttons.logout") }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
	</div>
</template>

<script>
import colorsMixin from "Mixins/colorsMixin";
import FrpIcon from "Components/icon/FrpIcon";
import userManager from "@/services/auth/userManager";

export default {
	name: "ResToolbar",
	mixins: [colorsMixin],
	data() {
		return {
			name: ''
		}
	},
	mounted() {
		this.name = JSON.parse(localStorage.getItem('oidc.user:https://id-api.frprf.ru:FRP-SERVICES-REX'))?.profile?.profile?.title;
	},
	methods: {
		logout() {
			userManager.signoutRedirect();
		}
	},
	components: {
		FrpIcon,
	}
};
</script>

<style scoped>
.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  height: 73px;
  background: #181939;
}
.toolbar--info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 37px;
  color: #FFFFFF;
}
</style>
