import { ApiTranchesUser } from "@/api/tranches/types/apiTranchesUser";
import { ApiProjectInfo } from "@/api/tranches/types/apiProjectInfo";
import { ApiAgreement } from "@/api/tranches/types/apiAgreement";
import { ApiManager } from "@/api/tranches/types/apiManager";
import { ApiTrancheSchedule } from "@/api/tranches/types/apiTrancheSchedule";
import { TrancheScheduleStatusTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleStatusTypeEnum";
import { ApiTrancheScheduleTranche } from "@/api/tranches/types/apiTrancheScheduleTranche";

export const mocks = {
	tranches: {
		permissions: {
			applicant: [
				"tranche:read",
				"tranche:update",
				"tranche:delete",
				"tranche:send",
				"tranche:generate",
				"tranche:withdraw"
			],
			projectManager: [
				"tranche:read",
				"tranche:reject",
				"tranche:accept"
			],
			dsp: [
				"tranche:read",
				"tranche:order-application",
				"tranche:edit-order-condition",
			],
			admin: [
				"tranche:read",
				"tranche:update",
				"tranche:delete",
				"tranche:send",
				"tranche:generate",
				"tranche:withdraw",
				"tranche:reject",
				"tranche:accept",
				"tranche:order-application",
				"tranche:edit-order-condition",
			]
		},
		tranchesUser: {
			id: "0000-0000-0000-0000-0000-0000",
			firstName: "Петр",
			lastName: "Петров",
			middleName: "Петрович",
			email: "petrov-petr-petrovich@cloud.com",
			roles: ["Administrator"]
		} as ApiTranchesUser,
		projectInfo: {
			id: 260,
			entryId: 2600,
			name: "ООО 'ЛЮТИК'",
			applicationNumber: 9999999,
			agreement: {
				projectId: 260,
				documentNumber: "9999999",
				signingDate: "2023-10-11T15:39:39.312Z",
				exactFrpSum: 45000000,
				supervisor: {
					id: 2,
					name: "Александр Еселевич",
					email: "eselevich@frprf.ru",
					phone: "+78888888888"
				} as ApiManager,
				manager: {
					id: 1,
					name: "Дмитрий Бурлаков",
					email: "burlakov@frprf.ru",
					phone: "+79999999999"
				} as ApiManager
			} as ApiAgreement,
			borrowerOrganization: {
				id: "3584",
				fullName: "ООО 'БАЗИС+' - 6662",
				shortName: "ООО 'БАЗИС+' КОМПАНИЯ"
			}
		} as ApiProjectInfo,
		emptyTrancheSchedule: {
			status: TrancheScheduleStatusTypeEnum.DRAFT,
			acceptDate: "",
			tranches: []
		} as ApiTrancheSchedule,
		trancheSchedule: {
			status: TrancheScheduleStatusTypeEnum.PENDING,
			acceptDate: "",
			tranches: [
				{
					number: 1,
					amount: 10000000,
					plannedDate: "2023-10-11T15:50:45.404Z",
					orderDate: "2023-10-11T15:50:45.404Z",
					orderCondition: "Какое-то условие"
				} as ApiTrancheScheduleTranche,
				{
					number: 2,
					amount: 20000000,
					plannedDate: "2023-10-11T15:50:45.404Z",
					orderDate: "",
					orderCondition: ""
				} as ApiTrancheScheduleTranche,
				{
					number: 3,
					amount: 15000000,
					plannedDate: "2023-10-11T15:50:45.404Z",
					orderDate: "",
					orderCondition: ""
				} as ApiTrancheScheduleTranche
			] as ApiTrancheScheduleTranche[]
		} as ApiTrancheSchedule,
		acceptedTrancheSchedule: {
			status: TrancheScheduleStatusTypeEnum.ACCEPTED,
			acceptDate: "2023-10-11T15:50:45.404Z",
			tranches: [
				{
					number: 1,
					amount: 1000000,
					plannedDate: "2023-10-11T15:50:45.404Z",
					orderDate: "2023-10-11T15:50:45.404Z",
					orderCondition: "Какое-то условие"
				} as ApiTrancheScheduleTranche,
				{
					number: 2,
					amount: 2000000,
					plannedDate: "2023-10-11T15:50:45.404Z",
					orderDate: "",
					orderCondition: ""
				} as ApiTrancheScheduleTranche,
				{
					number: 3,
					amount: 1500000,
					plannedDate: "2023-10-11T15:50:45.404Z",
					orderDate: "",
					orderCondition: ""
				} as ApiTrancheScheduleTranche
			] as ApiTrancheScheduleTranche[]
		} as ApiTrancheSchedule
	}
};
