import { stringifyQuery } from "@/utils/query";
import FrpInternalServerError from "@/views/errors/FrpInternalServerError.vue";
import FrpAccessDeniedError from "@/views/errors/FrpAccessDeniedError.vue";
import FrpPageNotFound from "@/views/errors/FrpPageNotFound.vue";
import FrpSilentRenew from "@/views/FrpSilentRenew.vue";
import Vue from "vue";
import FrpEmptyLayout from "@/views/layouts/FrpEmptyLayout.vue";
import VueRouter from "vue-router";
import RexLayout from "@/views/rex/layouts/RexLayout.vue";
import FrpCallback from "@/views/FrpCallback.vue";
import { RouteNames } from "@/router/rex/routes";
import { configureMiddleware } from "@/router/rex/middleware";

Vue.use(VueRouter);

const routes = [
	{
		path: "/callback.html",
		component: FrpCallback,
		meta: { public: true },
		name: RouteNames.CALLBACK
	},
	{
		path: "/silent-renew.html",
		component: FrpSilentRenew,
		meta: { public: true },
		name: RouteNames.SILENT_RENEW
	},
	{
		path: "/expertises/:id",
		component: RexLayout,
		name: RouteNames.REX,
	},
	{
		path: "/",
		component: FrpEmptyLayout,
		meta: { public: true },
		children: [
			{
				path: "internal-server-error",
				component: FrpInternalServerError,
				name: RouteNames.ERROR_INTERNAL_SERVER_ERROR
			},
			{
				path: "access-denied",
				component: FrpAccessDeniedError,
				name: RouteNames.ERROR_ACCESS_DENIED
			},
			{
				path: "*",
				component: FrpPageNotFound,
				name: RouteNames.ERROR_PAGE_NOT_FOUND
			}
		]
	}
];

const router = new VueRouter({
	routes,
	mode: "history",
	stringifyQuery: query => {
		// @ts-ignore
		let result = stringifyQuery(query);
		return result ? ("?" + result) : "";
	}
});

configureMiddleware(router);

export default router;
