import { Type } from "class-transformer";
import ApiBankAccountApplicationMessage from "@/api/bar/types/apiBankAccountApplicationMessage";

export default class ApiBankAccountApplicationMessagePersisted {
	id: string;
	createdAt: string;
	updatedAt: string;
	@Type(() => ApiBankAccountApplicationMessage)
	bankAccountApplicationMessage: ApiBankAccountApplicationMessage;
	constructor(
		id: string = "",
		createdAt: string = "",
		updatedAt: string = "",
		bankAccountApplicationMessage: ApiBankAccountApplicationMessage = new ApiBankAccountApplicationMessage()
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.bankAccountApplicationMessage = bankAccountApplicationMessage;
	}
}
