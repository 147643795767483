export default class ApiContractInfo {
	number: string;
	openingDate: string;

	constructor(
		number: string = "",
		openingDate: string = ""
	)
	{
		this.number = number;
		this.openingDate = openingDate;
	}
}
