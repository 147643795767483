export default class ApiBarUser {
	id: string;
	firstName: string;
	lastName: string;
	middleName: string;
	email: string;
	roles: string[];
	employeeId: string;
	
	constructor(
		id: string = "",
		firstName: string = "",
		lastName: string = "",
		middleName: string = "",
		email: string = "",
		roles: string[] = [],
		employeeId: string = ""
	)
	{
		this.id = id;
		this.firstName = firstName;
		this.lastName = lastName;
		this.middleName = middleName;
		this.email = email;
		this.roles = roles;
		this.employeeId = employeeId;
	}
}
