import ApiResultExpertise from "@/api/rex/types/reports/apiResultExpertise";
import ApiReportSovetInfo from "@/api/rex/types/reports/apiReportSovetInfo";

export default class ApiResultExpertiseResponse {
	counts: number;
	items: ApiResultExpertise[];
	sovetInfo: ApiReportSovetInfo;
	
	constructor(
		counts: number = 0,
		items: ApiResultExpertise[] = [],
		sovetInfo: ApiReportSovetInfo,
	)
	{
		this.counts = counts;
		this.items = items;
		this.sovetInfo = sovetInfo;
	}
}
