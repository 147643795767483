<template>
	<div class="info-wrap">
		<div class="info-wrap">
			<frp-icon @click="openWindow" class="icon-wrap cursor-pointer" src="ico_arrow-back" :color="colors.orange.blue"></frp-icon>
			<span class="info--name">{{ $t("common.examinationResults") }} {{info}}</span>
		</div>
		<div class="info--panel">
			<div class="info--block-left">
				<div class="info--row">
					<div class="info--color green-l"></div>
					<span class="info--text">{{ $t("common.recommendedForFinancing") }}</span>
				</div>
				<div class="info--row">
					<div class="info--color yellow-l"></div>
					<span class="info--text">{{ $t("common.recommendedWithReservations") }}</span>
				</div>
				<div class="info--row">
					<div class="info--color pink"></div>
					<span class="info--text">{{ $t("common.notRecommendedForFinancing") }}</span>
				</div>
				</div>
				<div class="info--block-right">
				<div class="info--row">
					<div class="info--color border"></div>
					<span class="info--text">{{ $t("common.examinationNotRequested") }}</span>
				</div>
				<div class="info--row">
					<div class="info--color gray-l"></div>
					<span class="info--text">{{ $t("common.examinationCreated") }}</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import colorsMixin from "@/mixins/colorsMixin";
import FrpIcon from "Components/icon/FrpIcon";

export default {
	name: "RexDocumentInfo",
	mixins: [colorsMixin],
	props: {
		info: {
			type: String,
			default: ''
		},
	},
	methods: {
		openWindow() {
			window.open(`https://lk.frprf.ru/experts/${this.$route.params.id}`);
		}
	},
	components: {
		FrpIcon,
	}
}
</script>
<style scoped lang="scss">
.info-wrap {
	display: flex; 
	flex-direction: row; 
	justify-content: space-between; 
	height: 120px; 
	align-items: center;
}
.info--name {
	font-size: 23px; 
	font-weight: 600; 
	line-height: 31px; 
	letter-spacing: 0; 
	text-align: center;
}
.info--panel {
	display: flex; 
	flex-direction: row;  
	width: 466px; 
	height: 62px; 
	box-shadow: 3px 6px 20px 0 #0000001A;
}
.info--block-left {
	width: 252px;
}
.info--block-right {
	width: 202px;
}
.info--row {
	display: flex;
	flex-direction: row;
	padding: 4px;
}
.info--color {
	width: 64px;
	height: 9px;
	border-radius: 5px;
}
.info--text {
	font-size: 9px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0.0015em;
	text-align: left;
	color: #002333;
	margin-left: 8px;
}
.green-l {
	background: #75C723;
}
.yellow-l {
	background: #F5DE8B;
}
.pink {
	background: #F5988B;
}
.border {
	border: 0.4px solid #8E8A8A;
}
.gray-l {
	border: 0.4px solid #8E8A8A;
	background: #D9D9D9;
}
.cursor-pointer {
	cursor: pointer;
}
.icon-wrap {
	width: 29px;
}
</style>
