import { Type } from "class-transformer";
import ApiBankAccountInfo from "@/api/bar/types/apiBankAccountInfo";

export default class ApiBankAccountInfoPersisted {
	id: string;
	createdAt: string;
	updatedAt: string;
	@Type(() => ApiBankAccountInfo)
	bankAccountInfo: ApiBankAccountInfo;

	constructor(
		id: string = "",
		createdAt: string = "",
		updatedAt: string = "",
		bankAccountInfo: ApiBankAccountInfo = new ApiBankAccountInfo()
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.bankAccountInfo = bankAccountInfo;
	}
}
