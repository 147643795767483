import { Type } from "class-transformer";
import ApiBankAccountInfo from "@/api/bar/types/apiBankAccountInfo";
import ApiBankAccountApplicationInfo from "@/api/bar/types/apiBankAccountApplicationInfo";

export default class ApiBankAccountApplicationInfoPersisted {
	id: string;
	createdAt: string;
	updatedAt: string;
	@Type(() => ApiBankAccountApplicationInfo)
	bankAccountApplicationInfo: ApiBankAccountApplicationInfo;

	constructor(
		id: string = "",
		createdAt: string = "",
		updatedAt: string = "",
		bankAccountApplicationInfo: ApiBankAccountApplicationInfo = new ApiBankAccountApplicationInfo()
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.bankAccountApplicationInfo = bankAccountApplicationInfo;
	}
}
