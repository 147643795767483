import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import ApiBarUser from "@/api/bar/types/apiBarUser";
import ApiBankAccountInfoPersisted from "@/api/bar/types/apiBankAccountInfoPersisted";
import { ApiGetBankAccountsParameters } from "@/api/bar/types/apiGetBankAccountsParameters";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import ApiBankAccountItems from "@/api/bar/types/apiBankAccountItems";
import ApiBankAccountApplicationItems from "@/api/bar/types/apiBankAccountApplicaionItems";
import ApiGetBankAccountApplicationsParameters from "@/api/bar/types/apiGetBankAccountApplicationsParameters";
import ApiBankAccountApplicationInfo from "@/api/bar/types/apiBankAccountApplicationInfo";
import ApiBankAccountApplicationInfoPersisted from "@/api/bar/types/apiBankAccountApplicationInfoPersisted";
import ApiBankAccountInfo from "@/api/bar/types/apiBankAccountInfo";
import ApiBankAccountApplicationMessagePersisted from "@/api/bar/types/apiBankAccountApplicationMessagePersisted";
import ApiBankAccountApplicationMessage from "@/api/bar/types/apiBankAccountApplicationMessage";
import ApiDocumentResponse from "@/api/bar/types/apiDocumentResponse";
import ApiApplication from "@/api/bar/types/apiApplication";
import ApiStatusHistoryItem from "@/api/bar/types/apiStatusHistoryItem";
import ApiBankAccountApplicationLegalDepartmentInfo from "@/api/bar/types/apiBankAccountApplicationLegalDepartmentInfo";
import { ApiBankAccountApplicationAccount } from "@/api/bar/types/apiBankAccountApplicationAccount";

export class BarController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getPermissions = async (): Promise<string[]> => {
		return await this.client.get<string[]>(urls.bar.permissions.get);
	};
	
	getCurrentUser = async (): Promise<ApiBarUser> => {
		const data = await this.client.get<ApiBarUser>(urls.bar.user.getCurrentUser);
		return plainToInstance(ApiBarUser, data);
	};
	
	getBankAccountApplicationsItems = async (parameters: ApiGetBankAccountApplicationsParameters): Promise<ApiBankAccountApplicationItems> => {
		const url = prepareUrl(urls.bar.bankAccountApplications.getBankAccountApplications, parameters);
		const data = await this.client.get<ApiBankAccountApplicationItems>(url);
		
		return plainToInstance(ApiBankAccountApplicationItems, data);
	};
	getBankAccountApplicationStatusHistoryItems = async (bankAccountApplicationId: string): Promise<ApiStatusHistoryItem[]> => {
		const url = urls.bar.bankAccountApplications.getBankAccountApplicationStatusHistoryItems.replace(urlTemplateParts.id, bankAccountApplicationId);
		const data = await this.client.get<ApiStatusHistoryItem[]>(url);
		
		return plainToInstance(ApiStatusHistoryItem, data);
	};
	
	createBankAccountApplication = async (bankAccountApplicationInfo: ApiBankAccountApplicationInfo): Promise<ApiBankAccountApplicationInfoPersisted> => {
		const data = await this.client.post<ApiBankAccountApplicationInfoPersisted>(urls.bar.bankAccountApplications.createBankAccountApplication,
			bankAccountApplicationInfo);
		
		return plainToInstance(ApiBankAccountApplicationInfoPersisted, data);
	};
	
	updateBankAccountApplication = async (bankAccountApplicationId: string,
		bankAccountApplicationInfo: ApiBankAccountApplicationInfo): Promise<void> => {
		const url = urls.bar.bankAccountApplications.updateBankAccountApplication.replace(urlTemplateParts.id, bankAccountApplicationId);
		await this.client.put(url, bankAccountApplicationInfo);
	};
	
	generateInformationalLetter = async (bankAccountApplicationId: string): Promise<Blob> => {
		const url = urls.bar.bankAccountApplications.generateInformationalLetter.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<Blob>(url, { responseType: "blob" }, true);
	};
	
	getInformationalLetterDocumentFileInfo = async (bankAccountApplicationId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.getFileInfo.replace(urlTemplateParts.id, bankAccountApplicationId);
		const data = await this.client.get<ApiDocumentResponse>(url);
		return plainToInstance(ApiDocumentResponse, data);
	};
	
	setInformationalLetterDocumentFile = async (bankAccountApplicationId: string, tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.setFile.replace(urlTemplateParts.id, bankAccountApplicationId);
		const data = await this.client.post<ApiDocumentResponse>(url, { tempFileId });
		
		return plainToInstance(ApiDocumentResponse, data);
	};
	
	setAcceptedStatusForInformationalLetterDocument = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.setStatus.accepted.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(url, {});
	};
	
	setNotRequiredStatusForInformationalLetterDocument = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.setStatus.notRequired.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(url, {});
	};
	
	setDeclinedStatusForInformationalLetterDocument = async (bankAccountApplicationId: string, explanation: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.setStatus.declined.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(prepareUrl(url, { explanation }), {});
	};
	
	getDirectWithdrawalAgreementDocumentFileInfo = async (bankAccountApplicationId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.getFileInfo.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		const data = await this.client.get<ApiDocumentResponse>(url);
		return plainToInstance(ApiDocumentResponse, data);
	};
	
	setDirectWithdrawalAgreementDocumentFile = async (bankAccountApplicationId: string, tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.setFile.replace(urlTemplateParts.id, bankAccountApplicationId);
		const data = await this.client.post<ApiDocumentResponse>(url, { tempFileId });
		
		return plainToInstance(ApiDocumentResponse, data);
	};
	
	setAcceptedStatusForDirectWithdrawalAgreementDocument = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.setStatus.accepted.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(url, {});
	};
	
	setNotRequiredStatusForDirectWithdrawalAgreementDocument = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.setStatus.notRequired.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(url, {});
	};
	
	setDeclinedStatusForDirectWithdrawalAgreementDocument = async (bankAccountApplicationId: string, explanation: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.setStatus.declined.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(prepareUrl(url, { explanation }), {});
	};
	
	getPaymentAcceptAgreementDocumentFileInfo = async (bankAccountApplicationId: string, accountId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.getFileInfo.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, accountId);
		const data = await this.client.get<ApiDocumentResponse>(url);
		return plainToInstance(ApiDocumentResponse, data);
	};
	
	setPaymentAcceptAgreementDocumentFile = async (bankAccountApplicationId: string, accountId: string, tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.setFile.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, accountId);
		const data = await this.client.post<ApiDocumentResponse>(url, { tempFileId });
		
		return plainToInstance(ApiDocumentResponse, data);
	};
	
	setAcceptedStatusForPaymentAcceptAgreementDocument = async (bankAccountApplicationId: string, accountId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.setStatus.accepted.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, accountId);
		return await this.client.put(url, {});
	};
	
	setNotRequiredStatusForPaymentAcceptAgreementDocument = async (bankAccountApplicationId: string, accountId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.setStatus.notRequired.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, accountId);
		return await this.client.put(url, {});
	};
	
	setDeclinedStatusForPaymentAcceptAgreementDocument = async (bankAccountApplicationId: string, accountId: string, explanation: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.setStatus.declined.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, accountId);
		return await this.client.put(prepareUrl(url, { explanation }), {});
	};
	
	getBankAccountApplicationLegalDepartmentInfo = async (bankAccountApplicationId: string): Promise<ApiBankAccountApplicationLegalDepartmentInfo> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.getLegalDepartmentInfo.replace(urlTemplateParts.id, bankAccountApplicationId);
		const data = await this.client.get<ApiBankAccountApplicationLegalDepartmentInfo>(url);
		return plainToInstance(ApiBankAccountApplicationLegalDepartmentInfo, data);
	};
	
	getBankAccountApplicationLegalDepartmentRequestDocuments = async (bankAccountApplicationId: string): Promise<ApiDocumentResponse[]> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.getDocuments.replace(urlTemplateParts.id, bankAccountApplicationId);
		const data = await this.client.get<ApiDocumentResponse[]>(url, {});
		return plainToInstance(ApiDocumentResponse, data);
	};
	
	sendBankAccountApplicationLegalDepartmentRequest = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.sendLegalDepartmentRequest.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post(url, {});
	};
	setBankAccountApplicationLegalDepartmentResponsiblePerson = async (bankAccountApplicationId: string, legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo): Promise<void> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.setResponsiblePerson.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post(url, legalDepartmentInfo );
	};
	acceptBankAccountApplicationLegalDepartment = async (bankAccountApplicationId: string, legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo): Promise<ApiBankAccountApplicationLegalDepartmentInfo> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.accept.replace(urlTemplateParts.id, bankAccountApplicationId);
		const data = await this.client.post(url, legalDepartmentInfo);
		return plainToInstance(ApiBankAccountApplicationLegalDepartmentInfo, data);
	};
	declineBankAccountApplicationLegalDepartment = async (bankAccountApplicationId: string, legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo): Promise<ApiBankAccountApplicationLegalDepartmentInfo> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.decline.replace(urlTemplateParts.id, bankAccountApplicationId);
		const data = await this.client.post(url, legalDepartmentInfo);
		return plainToInstance(ApiBankAccountApplicationLegalDepartmentInfo, data);
	};
	resetStatusesForBankAccountApplicationLegalDepartment = async (bankAccountApplicationId: string, legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo): Promise<ApiBankAccountApplicationLegalDepartmentInfo> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.resetStatuses.replace(urlTemplateParts.id, bankAccountApplicationId);
		const data = await this.client.post(url, legalDepartmentInfo);
		return plainToInstance(ApiBankAccountApplicationLegalDepartmentInfo, data);
	};
	uploadLegalDepartmentDocumentFileInfo = async (bankAccountApplicationId: string, tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.sendDocumentFileInfo.replace(urlTemplateParts.id, bankAccountApplicationId);
		const data = await this.client.post(url, { tempFileId });
		return plainToInstance(ApiDocumentResponse, data);
	};
	
	getMessages = async (bankAccountApplicationId: string): Promise<ApiBankAccountApplicationMessagePersisted[]> => {
		const url = urls.bar.bankAccountApplications.messenger.getMessages.replace(urlTemplateParts.id, bankAccountApplicationId);
		
		const data = await this.client.get<ApiBankAccountApplicationMessagePersisted[]>(url);
		return plainToInstance(ApiBankAccountApplicationMessagePersisted, data);
	};
	
	sendMessage = async (bankAccountApplicationId: string, message: ApiBankAccountApplicationMessage): Promise<ApiBankAccountApplicationMessagePersisted> => {
		const url = urls.bar.bankAccountApplications.messenger.sendMessage.replace(urlTemplateParts.id, bankAccountApplicationId);
		
		const data = await this.client.post<ApiBankAccountApplicationMessagePersisted>(url, message);
		return plainToInstance(ApiBankAccountApplicationMessagePersisted, data);
	};
	
	deleteMessage = async (bankAccountApplicationId: string, messageId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.messenger.deleteMessage.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, messageId);
		
		await this.client.delete(url);
	};
	
	deleteBankAccountApplicationDocument = async (bankAccountApplicationId: string, documentId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.deleteBankAccountApplicationDocument.replace(urlTemplateParts.id, bankAccountApplicationId).replace(urlTemplateParts.subId, documentId);
		return await this.client.delete(url);
	};
	
	getBankAccountItems = async (parameters: ApiGetBankAccountsParameters): Promise<ApiBankAccountItems> => {
		const data = await this.client.get<ApiBankAccountItems>(prepareUrl(urls.bar.bankAccounts.getBankAccounts, parameters));
		return plainToInstance(ApiBankAccountItems, data);
	};
	
	getBankAccountApplication = async (bankAccountApplicationId: string): Promise<ApiBankAccountApplicationInfoPersisted> => {
		const url = urls.bar.bankAccountApplications.getBankAccountApplication.replace(urlTemplateParts.id, bankAccountApplicationId);
		const data = await this.client.get<ApiBankAccountApplicationInfoPersisted>(url);
		
		return plainToInstance(ApiBankAccountApplicationInfoPersisted, data);
	};
	
	getBankAccountApplicationAccounts = async (bankAccountApplicationId: string): Promise<ApiBankAccountApplicationAccount[]> => {
		const url = urls.bar.bankAccountApplications.getBankAccountApplicationAccounts.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<ApiBankAccountApplicationAccount[]>(url);
	};
	
	getBankAccount = async (bankAccountId: string): Promise<ApiBankAccountInfoPersisted> => {
		const url = urls.bar.bankAccounts.getBankAccount.replace(urlTemplateParts.id, bankAccountId);
		const data = await this.client.get<ApiBankAccountInfoPersisted>(url);
		
		return plainToInstance(ApiBankAccountInfoPersisted, data);
	};
	
	getApplicationsForBankAccount = async (accountNumber: string): Promise<ApiApplication[]> => {
		const url = urls.bar.bankAccounts.getBankAccountApplications.replace(urlTemplateParts.id, accountNumber);
		const data = await this.client.get<ApiApplication[]>(url);
		
		return plainToInstance(ApiApplication, data);
	};
	
	createBankAccount = async (bankAccountInfo: ApiBankAccountInfo): Promise<ApiBankAccountInfoPersisted | any> => {
		const data = await this.client.post<ApiBankAccountInfoPersisted>(urls.bar.bankAccounts.createBankAccount, bankAccountInfo);
		return plainToInstance(ApiBankAccountInfoPersisted, data);
	};
	
	updateBankAccount = async (bankAccountId: string, bankAccountInfo: ApiBankAccountInfo): Promise<void> => {
		const url = urls.bar.bankAccounts.updateBankAccount.replace(urlTemplateParts.id, bankAccountId);
		await this.client.put(url, bankAccountInfo);
	};
	
	checkBankAccount = async (parameters: { accountNumber: string, loanApplicationNumber?: string }): Promise<boolean> => {
		const url = prepareUrl(urls.bar.bankAccounts.getBankAccounts, parameters)
		const { items } = await this.client.get<ApiBankAccountItems>(url, {});
		
		return Boolean(items.length);
	};
	
	checkBankAccountPayments = async (accountNumber: string): Promise<{ hasPayments: boolean }> => {
		return await this.client.get<{ hasPayments: boolean }>(urls.bar.bankAccounts.checkBankAccountPayments.replace(urlTemplateParts.id, accountNumber), {});
	};
}
