import { Permissions } from "@/constants/permissions";
import AbortService from "@/services/abortService";
import { AuthorizationController } from "@/api/authorization/index";
import { PERMISSIONS_STORAGE_KEY } from "@/constants/localStorage";
import * as storage from "local-storage";
import { getApplicationMode } from "@/utils/configuration";
import { ApplicationModeType } from "@/types/ApplicationModeType";
import { LoanAuthorizationController } from "@/api/loan/authorization";
import { BodController } from "@/api/bod";
import { BarController } from "@/api/bar";
import { TranchesController } from "@/api/tranches";

const abortService = new AbortService();
abortService.initialize();

const mode = getApplicationMode();

let authorizationController: any;
switch (mode) {
	case ApplicationModeType.OLK:
		authorizationController = new AuthorizationController(abortService);
		break;
	case ApplicationModeType.LOAN:
		authorizationController = new LoanAuthorizationController(abortService);
		break;
	case ApplicationModeType.BOD:
		authorizationController = new BodController(abortService);
		break;
	case ApplicationModeType.BAR:
		authorizationController = new BarController(abortService);
		break;
	case ApplicationModeType.TRANCHES:
		authorizationController = new TranchesController(abortService);
		break;
}

export default class PermissionsResolver {
	constructor() {
	}
	
	getPermissionsFromStorage(): Permissions[] {
		return storage.get<Permissions[]>(PERMISSIONS_STORAGE_KEY);
	}
	
	async updatePermissionsStorage() {
		const permissions = await authorizationController.getPermissions();
		
		storage.set(PERMISSIONS_STORAGE_KEY, permissions);
	}
	
	resetPermissionsStorage() {
		storage.remove(PERMISSIONS_STORAGE_KEY);
	}
	
	async resolve(): Promise<Permissions[]> {
		let permissions = this.getPermissionsFromStorage();
		
		if(!permissions) {
			await this.updatePermissionsStorage();
			permissions = this.getPermissionsFromStorage();
		}
		
		return permissions;
	}
}
