import Vue from "vue";
import Vuex, {ActionTree, MutationTree} from "vuex";
// @ts-ignore
import RootState from "@/store/rex/types/rootState";
import {PageModeType} from "@/store/rex/types/pageModeType";
import alertsModule from "@/store/modules/alerts";

Vue.use(Vuex);

class DefaultStateBuilder {
		constructor() {
	}
	
	build() {
		return new RootState(PageModeType.OK);
	}
}

const state = (new DefaultStateBuilder()).build();

const actions = <ActionTree<RootState, any>>{};

const mutations = <MutationTree<any>>{};

const store = new Vuex.Store({
	state,
	mutations,
	actions,
	modules: {
		[alertsModule.namespace]: {
			...alertsModule
		}
	}
});

export default store;
