import { ApiSupervisorManagerInfo, ApiSupervisorManagerInfoHelper } from "@/api/bar/types/apiSupervisorManagerInfo";

export interface ApiProjectInfo {
	id: number;
	number: number;
	entryId: number;
	counterpartyShortName: string;
	counterpartyLongName: string;
	inn: string;
	ogrn: string;
	supervisorManagerInfo: ApiSupervisorManagerInfo;
}

export class ApiProjectInfoHelper {
	static getEmpty(): ApiProjectInfo {
		return {
			id: 0,
			number: 0,
			entryId: 0,
			counterpartyShortName: "",
			counterpartyLongName: "",
			inn: "",
			ogrn: "",
			supervisorManagerInfo: ApiSupervisorManagerInfoHelper.getEmpty(),
		};
	}
}