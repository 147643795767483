import { User } from "oidc-client-ts";
import SecurityService from "@/services/auth/securityService";
import { RateLimiter } from "limiter";
import TooManyRequestsException from "@/exceptions/tooManyRequestsException";
import LoanLocalStorageService from "@/services/localStorage/loanLocalStorageService";
import { checkUserLoaded } from "@/services/auth/userManager";
import httpService from "@/api/client/httpService";
import { getApplicationMode } from "@/utils/configuration";
import { ApplicationModeType } from "@/types/ApplicationModeType";
import InvalidArgumentException from "@/exceptions/invalidArgumentException";
import NotDefinedOrNullException from "@/exceptions/notDefinedOrNullException";

const loanLocalStorageService = new LoanLocalStorageService();

const rateLimiter = new RateLimiter({ tokensPerInterval: 15, interval: "minute" });

const securityService = new SecurityService();

const addAuthInterceptor = () => {
	httpService.interceptors.request.use(async (config: any) => {
		const mode = getApplicationMode();

		switch (mode) {
			case ApplicationModeType.LOAN:
			{
				if(!loanLocalStorageService.token)
					throw new NotDefinedOrNullException("Bearer token");

				config.headers.Authorization = `Bearer ${loanLocalStorageService.token}`;
				return config;
			}
			case ApplicationModeType.OLK:
			case ApplicationModeType.BOD:
			case ApplicationModeType.BAR:
			case ApplicationModeType.REX:
			{

				let isUserLoaded = checkUserLoaded();

				if(!isUserLoaded)
					return config;

				try {
					const token = await securityService.getToken();

					if(!token)
						throw new NotDefinedOrNullException("Bearer token");

					config.headers.Authorization = `Bearer ${token}`;
					return config;
				} catch (e) {
					console.error(e);
					throw e;
				}
			}
			default:
				throw new InvalidArgumentException(mode, mode);
		}

	}, (error) => {
		return Promise.reject(error);
	});


	httpService.interceptors.response.use((response) => {
		return response;
	}, async (error) => {
		// Отличные от 401 не обрабатываются
		if(!error.response || error.response.status !== 401)
			return Promise.reject(error);

		// Повторный запрос с обновленным токеном
		try {
			//@ts-ignore
			let { access_token }: User = await securityService.signinSilent();
			const config = error.config;
			config.headers["Authorization"] = `Bearer ${access_token}`;

			if(rateLimiter.tryRemoveTokens(1)) {
				return await httpService.request(config);
			} else {
				throw new TooManyRequestsException("Число повторных попыток обращения к серверу превысило допустимое значение.");
			}
		} catch (ex) {
			// Сессия сбрасывается, если возникла ошибка при обновлении токена
			await securityService.signOut();
		}
	});
};

export default addAuthInterceptor;
