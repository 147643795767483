import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";

export default class ApiStatusHistoryItem {
	id: string;
	bankAccountApplicationId: string;
	createdAt: string;
	status: BankAccountApplicationStatusTypeEnum;
	creatorId: string;
	creatorShortName: string;

	constructor(
		id: string = "",
		bankAccountApplicationId: string = "",
		createdAt: string = "",
		status: BankAccountApplicationStatusTypeEnum = BankAccountApplicationStatusTypeEnum.IN_PROGRESS,
		creatorId: string = "",
		creatorShortName: string = ""
	)
	{
		this.id = id;
		this.bankAccountApplicationId = bankAccountApplicationId;
		this.createdAt = createdAt;
		this.status = status;
		this.creatorId = creatorId;
		this.creatorShortName = creatorShortName;
	}
}
