import { Type } from "class-transformer";
import ApiBankAccountInfoPersisted from "@/api/bar/types/apiBankAccountInfoPersisted";
import ApiBankAccountApplicationInfoPersisted from "@/api/bar/types/apiBankAccountApplicationInfoPersisted";

export default class ApiBankAccountApplicationItems {
	@Type(() => ApiBankAccountApplicationInfoPersisted)
	items: ApiBankAccountApplicationInfoPersisted[];
	totalCount: number;
	constructor(
		items: ApiBankAccountApplicationInfoPersisted[] = [],
		totalCount: number = 0
	) {
		this.items = items;
		this.totalCount = totalCount;
	}
}
