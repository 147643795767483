<template>
	<router-view/>
</template>

<script>
export default {
	name: "RexApp"
};
</script>
<style>
::-webkit-scrollbar-track {
	padding: 2px 0;
}
::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #ccc;
}

</style>
