<template>
	<div>
		<rex-toolbar></rex-toolbar>
		<div class="info-wrap">
			<rex-document-info :info="value"></rex-document-info>
		</div>
		<div class="table-wrap">
			<rex-table :dataList="dataTable"></rex-table>
		</div>
		<rex-footer>
			<v-alert
				class="alert-properties"
				type="error"
				:value="showAlert"
				closable
			>{{ textAlert }}
				<template v-slot:close="{ toggle }">
					<frp-icon @click="closeAlert()" src="ico_close" :color="colors.white.base" class="mr-3 icon-properties"></frp-icon>
				</template></v-alert>
		</rex-footer>
	</div>
</template>

<script>
import RexToolbar from "@/views/rex/components/toolbar/RexToolbar.vue";
import RexFooter from "@/views/rex/components/layouts/RexFooter.vue";
import RexTable from "@/views/rex/views/RexDocument/components/ResultExpertiseTable.vue";
import RexDocumentInfo from "@/views/rex/views/RexDocument/components/RexDocumentInfo.vue";
import SecurityService from "@/services/auth/securityService";
import { ReportsController } from "@/api/rex/reports";
import AbortService from "@/services/abortService";
import alertMixin from "@/mixins/alertMixin";
import colorsMixin from "@/mixins/colorsMixin";
import FrpIcon from "Components/icon/FrpIcon";

const abortService = new AbortService();
const securityService = new SecurityService();
const reportsController = new ReportsController(abortService);
export default {
	name: "RexDocument",
	mixins: [colorsMixin, alertMixin],
	data() {
		return {
			value: '',
			dataTable: [],
			fieldsList: ["nte", "pte", "pre", "fie", "zle", "minprom", "providing_bank_guarantee", "main_conditions_for_granting", "expertise_sootvetsviya_nacional", "expertise_sootvetsviya"],
			textAlert: "Возникла непредвиденная ошибка.",
			showAlert: false,
		};
	},
	mounted() {
		this.getData()
	},
	created() {
		abortService.initialize();
	},
	methods: {
		async getData() {
			let resultExpertise;
			const token = await securityService.getToken();
			try {
				let { data } = await reportsController.getResultExpertise(this.$route.params.id, token);
				resultExpertise = data.items
				Object.keys(resultExpertise).forEach(key => {
					let item = resultExpertise[key];
					const obj = {
						company: `${item?.COMPANY?.NAME !== null ? item?.COMPANY?.NAME : ''} $ ${item?.PROJECT_UF_NAME !== null ? item?.PROJECT_UF_NAME : ''}`,
						number: item?.PROJECT_UF_NUMBER,
						manager: item?.MANAGER?.NAME,
						financialExpert: item?.FINANCIAL_EXPERT?.NAME,
						sum: item?.PROJECT_UF_SUM_REQUEST.toLocaleString(),
						position: item?.TOTAL_POSITION_FRP.TEXT
					}

					for(let i = 0; i < this.fieldsList.length; i++) {
						let name = this.fieldsList[i]
						let field = item?.RESULT_EXPERTISE_BY_TYPE.find((el) => el.TYPE_EXPERTISE_INFO.CODE === name)
						obj[name] = {}
						obj[name].value = field.RESULT.IS_EXPERTISE_STATUS
						obj[name].comment = field?.COMMENT_LIST.length ? field?.COMMENT_LIST[0]?.TEXT.split('\n') : field?.COMMENT_LIST
						obj[name].number = item?.PROJECT_UF_NUMBER
						obj[name].name = field?.TYPE_EXPERTISE_INFO?.NAME
					}
					this.dataTable.push(obj);
				});
				this.value = `${data.sovetInfo.TYPE} № ${data.sovetInfo.NUMBER} от ${data.sovetInfo.DATE}`;
			} catch (e) {
				console.log(e);
				this.showAlert = true;
				//alertService.addError(e);
				//AlertHelper.handleGeneralRequestErrors(e);
			}
		},
		closeAlert() {
			this.showAlert = false;
		}
	},
	components: {
		RexDocumentInfo,
		RexTable,
		RexFooter,
		RexToolbar,
		FrpIcon,
	}
}
</script>
<style scoped lang="scss">
.info-wrap {
	margin: 16px;
}
.table-wrap {
	margin: 16px;
	overflow: auto;
	box-shadow: 3px 6px 20px 0 #0000001A;
}
.alert_properties {
	position: relative;
}
.icon-properties {
	cursor: pointer;
	position: relative;
	top: -16px;
	left: 21px;
}
</style>
