export interface ApiSupervisorManagerInfo {
	fullName: string;
	email: string;
}

export class ApiSupervisorManagerInfoHelper {
	static getEmpty(): ApiSupervisorManagerInfo {
		return {
			fullName: "",
			email: ""
		};
	}
}