import { SourceTypeEnum } from "@/store/bar/types/sourceType";
import ApiBankAccountData from "@/api/bar/types/apiBankAccountData";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import ApiContractInfo from "@/api/bar/types/apiContractInfo";
import { ApiProjectInfo, ApiProjectInfoHelper } from "@/api/bar/types/apiProjectInfo";

export default class ApiBankAccountApplicationInfo {
	source: SourceTypeEnum;
	applicationNumber: number;
	loanApplicationNumber: number;
	status: BankAccountApplicationStatusTypeEnum;
	bankId: string;
	bankBranchId: string;
	isLegalDepartmentRequestSent: boolean;
	contract: ApiContractInfo;
	accounts: ApiBankAccountData[];
	projectInfo: ApiProjectInfo;

	constructor(
		source: SourceTypeEnum = SourceTypeEnum.UNKNOWN,
		applicationNumber: number = 0,
		loanApplicationNumber: number = 0,
		status: BankAccountApplicationStatusTypeEnum = BankAccountApplicationStatusTypeEnum.IN_PROGRESS,
		bankId: string = "",
		bankBranchId: string = "",
		isLegalDepartmentRequestSent: boolean = false,
		contract: ApiContractInfo = new ApiContractInfo(),
		accounts: ApiBankAccountData[] = [],
		projectInfo: ApiProjectInfo = ApiProjectInfoHelper.getEmpty()
	)
	{
		this.source = source;
		this.applicationNumber = applicationNumber;
		this.loanApplicationNumber = loanApplicationNumber;
		this.status = status;
		this.bankId = bankId;
		this.bankBranchId = bankBranchId;
		this.isLegalDepartmentRequestSent = isLegalDepartmentRequestSent;
		this.contract = contract;
		this.accounts = accounts;
		this.projectInfo = projectInfo;
	}
}
