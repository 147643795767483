import { Type } from "class-transformer";
import ApiBankAccountInfoPersisted from "@/api/bar/types/apiBankAccountInfoPersisted";

export default class ApiBankAccountItems {
	@Type(() => ApiBankAccountInfoPersisted)
	items: ApiBankAccountInfoPersisted[];
	totalCount: number;
	constructor(
		items: ApiBankAccountInfoPersisted[] = [],
		totalCount: number = 0
	) {
		this.items = items;
		this.totalCount = totalCount;
	}
}
