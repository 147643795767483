import { render, staticRenderFns } from "./RexToolbar.vue?vue&type=template&id=e0b315b0&scoped=true"
import script from "./RexToolbar.vue?vue&type=script&lang=js"
export * from "./RexToolbar.vue?vue&type=script&lang=js"
import style0 from "./RexToolbar.vue?vue&type=style&index=0&id=e0b315b0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0b315b0",
  null
  
)

export default component.exports