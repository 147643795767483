<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="dataList"
			fixed-header
			:hide-default-footer="true"
			class="elevation-1"
			:height="'calc(100vh - 307px)'"
			item-value="name"
			hide-default-footer
			disable-pagination>
			<template v-slot:item.company="{ value }">
				<div class="unit-title">
					{{value.split('$')[0]}}
				</div>
				<div class="unit-text">
					{{value.split('$')[1]}}
				</div>
			</template>
			<template v-slot:item.nte="{ value }">
				<div class="icon-info icon-radius icon-radius" :class="{ 'border-black' : value.value === 0 }" :style="{ background: getColor(value.value) }">
					<frp-icon
							v-if="value.comment.length"
							src="ico_info2"
							@click="changeInfo(true, value.comment, value.number, value.name)"
							class="icon"></frp-icon>
				</div>
			</template>
			<template v-slot:item.pte="{ value }">
				<div class="icon-info icon-radius icon-radius" :class="{ 'border-black' : value.value === 0 }" :style="{ background: getColor(value.value) }">
					<frp-icon
							v-if="value.comment.length"
							src="ico_info2"
							@click="changeInfo(true, value.comment, value.number, value.name)"
							class="icon"></frp-icon>
				</div>
			</template>
			<template v-slot:item.pre="{ value }">
				<div class="icon-info icon-radius icon-radius" :class="{ 'border-black' : value.value === 0 }" :style="{ background: getColor(value.value) }">
					<frp-icon
							v-if="value.comment.length"
							src="ico_info2"
							@click="changeInfo(true, value.comment, value.number, value.name)"
							class="icon"></frp-icon>
				</div>
			</template>
			<template v-slot:item.fie="{ value }">
				<div class="icon-info icon-radius icon-radius" :class="{ 'border-black' : value.value === 0 }" :style="{ background: getColor(value.value) }">
					<frp-icon
							v-if="value.comment.length"
							src="ico_info2"
							@click="changeInfo(true, value.comment, value.number, value.name)"
							class="icon"></frp-icon>
				</div>
			</template>
			<template v-slot:item.zle="{ value }">
				<div class="icon-info icon-radius icon-radius" :class="{ 'border-black' : value.value === 0 }" :style="{ background: getColor(value.value) }">
					<frp-icon
							v-if="value.comment.length"
							src="ico_info2"
							@click="changeInfo(true, value.comment, value.number, value.name)"
							class="icon"></frp-icon>
				</div>
			</template>
			<template v-slot:item.expertise_sootvetsviya="{ value }">
				<div class="icon-info icon-radius" :class="{ 'border-black' : value.value === 0 }" :style="{ background: getColor(value.value) }">
					<frp-icon
							v-if="value.comment.length"
							src="ico_info2"
							@click="changeInfo(true, value.comment, value.number, value.name)"
							class="icon"></frp-icon>
				</div>
			</template>
			<template v-slot:item.minprom="{ value }">
				<div class="icon-info icon-radius" :class="{ 'border-black' : value.value === 0 }" :style="{ background: getColor(value.value) }">
					<frp-icon
							v-if="value.comment.length"
							src="ico_info2"
							@click="changeInfo(true, value.comment, value.number, value.name)"
							class="icon"></frp-icon>
				</div>
			</template>
			<template v-slot:item.expertise_sootvetsviya_nacional="{ value }">
				<div class="icon-info icon-radius" :class="{ 'border-black' : value.value === 0 }" :style="{ background: getColor(value.value) }">
					<frp-icon
							v-if="value.comment.length"
							src="ico_info2"
							@click="changeInfo(true, value.comment, value.number, value.name)"
							class="icon"></frp-icon>
				</div>
			</template>
			<template v-slot:item.providing_bank_guarantee="{ value }">
				<div class="icon-info icon-radius" :class="{ 'border-black' : value.value === 0 }" :style="{ background: getColor(value.value) }">
					<frp-icon
							v-if="value.comment.length"
							src="ico_info2"
							@click="changeInfo(true, value.comment, value.number, value.name)"
							class="icon"></frp-icon>
				</div>
			</template>
			<template v-slot:item.main_conditions_for_granting="{ value }">
					<div class="icon-info icon-radius" :class="{ 'border-black' : value.value === 0 }" :style="{ background: getColor(value.value) }">
						<frp-icon
								v-if="value.comment.length"
								src="ico_info2"
								@click="changeInfo(true, value.comment, value.number, value.name)"
								class="icon"></frp-icon>
					</div>
				</template>
		</v-data-table>
		<rex-dialog :show="showDialog" :list="dialogList" :number="dialogNumber" :name="dialogTitle" @hide="changeInfo(false, [], '', '')" ></rex-dialog>
	</div>
</template>
<script>
import FrpIcon from "@/components/icon/FrpIcon.vue";
import RexDialog from "@/views/rex/views/RexDocument/components/RexDialog.vue";
import colorsMixin from "@/mixins/colorsMixin";

export default {
	name: "ResultExpertiseTable",
	mixins: [colorsMixin],
	props: {
		dataList: {
			type: Array,
			default : []
		}
	},
	components: {RexDialog, FrpIcon},
	data() {
		return {
			showDialog: false,
			dialogList: [],
			dialogNumber: "",
			dialogTitle: "",
			headers: [
				{ text: this.$t('tables.requestId'), sortable: false, value: "number" },
				{ text: this.$t('tables.projectName'), sortable: false, value: "company", width: "424px"  },
				{ text: this.$t('tables.loanTotal'), sortable: false, value: "sum" },
				{ text: this.$t('tables.projectManager'), sortable: false, value: "manager" },
				{ text: this.$t('tables.financialExpert'), sortable: false, value: "financialExpert" },
				{ text: this.$t('tables.nte'), sortable: false, value: "nte" },
				{ text: this.$t('tables.pte'), sortable: false, value: "pte" },
				{ text: this.$t('tables.pre'), sortable: false, value: "pre" },
				{ text: this.$t('tables.fie'), sortable: false, value: "fie" },
				{ text: this.$t('tables.zle'), sortable: false, value: "zle" },
				{ text: this.$t('tables.minprom'), sortable: false, value: "minprom" },
				{ text: this.$t('tables.mainConditions'), sortable: false, value: "main_conditions_for_granting" },
				{ text: this.$t('tables.bankGuarantee'), sortable: false, value: "providing_bank_guarantee" },
				{ text: this.$t('tables.complianceExpertiseNational'), sortable: false, value: "expertise_sootvetsviya_nacional" },
				{ text: this.$t('tables.complianceExpertise'), sortable: false, value: "expertise_sootvetsviya" },
				{ text: this.$t('tables.fundPosition'), sortable: false, value: "position" },
				],
		}
	},
	methods: {
		getColor (index) {
			switch(index) {
				case 1:
					return '#75C72380' // Рекомендован к финансированию
				case 3:
					return '#F5DE8B' // Рекомендован с оговорками
				case 2:
					return '#F5988B' // Не рекомендован к финансированию
				case 0:
					return '#fff' // Экспертиза не запрошена
				case 100:
					return '#d9d9d9' // Экспертиза создана
				default:
					return ''
			}
		},
		changeInfo(val, list, num, name) {
			this.showDialog = val;
			this.dialogList = list;
			this.dialogNumber = num !== null ? num.toString() : "";
			this.dialogTitle = name;
		}
	},
}
</script>

<style scoped>
.icon {
	position: relative; 
	top: -15px; 
	left: 29px; 
	cursor: pointer;
}
.icon-info {
	width: 46px; 
	height: 28px;
}
.icon-radius {
	border-radius: 5px;
}
.border-black {
	border: 1px solid #000
}
.unit-title {
	color: #002333; 
	text-decoration: underline;
	padding: 16px 16px 0 16px;
}
.unit-text {
	color: #6C6D70;
	padding: 0 16px 16px 16px;
}
::v-deep tbody tr:nth-of-type(even) {
	background: #f5f5f5;
}
</style>
