import Vue from "vue";
import Vuetify from "vuetify";
import ru from "vuetify/es5/locale/ru";
import "@/plugins/vuetify/style.scss";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				primary: {
					base: "#002333",
					darken1: "#000000",
					lighten1: "#181939",
					lighten3: "#F8F8FA",
					lighten4: "#F5F5F5"
				},
				secondary: {
					base: "#DD052B",
					lighten1: "#FCE5EA",
					lighten2: "#DC0032",
					lighten4: "#FCE6EB"
				},
				warning: {
					base: "#FE7A00",
					lighten1: "#FFF2E6",
					darken1: "#E89900"
				},
				grey: {
					base: "#6C6D70",
					lighten1: "#F2F5F5",
					lighten2: "#E2E3EF",
					lighten3: "#939393",
					lighten4: "#DCDCDC",
					darken1: "#546072",
					darken2: "#D9D9D9",
					darken3: "#262626",
					darken4: "#FAFAFA"
				},
				blue: {
					base: "#0D74BB",
					lighten1: "#1B89D6",
					lighten4: "#E6F1F8",
					darken2: "#0F6AA8",
					darken3: "#0F1F34",
					darken4: "#0A1729"
				},
				green: {
					base: "#01865B",
					lighten1: "#E6F3EF",
					lighten2: "#21C562",
					lighten3: "#27AE60"
				},
				accent: "#A989EE",
				error: "#FB104A",
				success: "#29CC97",
				white: {
					base: "#FFFFFF",
					darken1: "#F0F1F5",
					darken2: "#ECECEC",
					darken3: "#E6E7EA",
					darken4: "#DFE0EB",
					lighten1: "#F4F4F4"
					//В теме VuetifyParsedThemeItem(Vuetify) нет Darken5, к тому darken 5 не используется
					//darken5: "#C4C4C4"
				},
				orange: {
					base: "#FFA800"
				},
				red: {
					lighten4: "#FFE7E7"
				}
			}
		}
	},
	lang: {
		locales: { ru },
		current: "ru"
	}
});
