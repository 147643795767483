export default class ApiApplication {
	id: string;
	number: number;
	createdAt: string;

	constructor(
		id: string = "",
		number: number = 0,
		createdAt: string = ""
	)
	{
		this.id = id;
		this.number = number;
		this.createdAt = createdAt;
	}
}
