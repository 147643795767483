import { ApplicationModeType } from "@/types/ApplicationModeType";
import InvalidArgumentException from "@/exceptions/invalidArgumentException";

export const getApplicationMode = (): ApplicationModeType => {
	let rawValue = String(process.env.VUE_APP_MODE).trim();

	switch (rawValue) {
		case "OLK":
			return ApplicationModeType.OLK;
		case "LOAN":
			return ApplicationModeType.LOAN;
		case "BOD":
			return ApplicationModeType.BOD;
		case "BAR":
			return ApplicationModeType.BAR;
		case "TRANCHES":
			return ApplicationModeType.TRANCHES;
		case "REX":
			return ApplicationModeType.REX;
		default:
			throw new InvalidArgumentException("Ошибка в конфигурации VUE_APP_MODE", rawValue);
	}
};
