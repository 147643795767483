<template>
	<v-footer height="64" color="primary lighten-1" class="footer-wrap">
		<frp-icon class="mr-8" src="ico_bod-logo" :color="colors.white.base"></frp-icon>
		<slot></slot>
		<div></div>
	</v-footer>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";

export default {
	name: "RexFooter",
	mixins: [colorsMixin],
	components: { FrpIcon }
};
</script>
<style scoped>
.footer-wrap {
	display: flex;
	justify-content: space-between;
}
</style>
