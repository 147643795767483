<template>
	<v-dialog
		v-model="show"
		width="552"
		height="318"
		>
	<v-card>
		<div class="dialog--row">
			<v-card-title class="dialog--title">{{ name }}</v-card-title>
		</div>
		<span class="dialog--subtitle">{{ number }}</span>
		<span class="dialog--name">{{ $t('fields.comment.label') }}</span>
		<div v-for="item in list">
			<div class="card--text">{{ item }}</div>
		</div>
		<v-card-actions style="justify-content: end;">
			<v-btn
			@click="close"
			color="blue"
			style="color: #fff;">{{ $t("buttons.close") }}</v-btn>
		</v-card-actions>
	</v-card>
	</v-dialog>
</template>

<script>

export default {
	name: "RexDialog",
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Array,
			default: () => []
		},
		number: {
			type: String,
			default: ""
		},
		name: {
			type: String,
			default: ""
		}
	}, 
	methods: {
		close() {
			this.$emit('hide', false, [], '')
		}
  }
}
</script>

<style scoped>
.dialog--row {
	display: flex;
	flex-direction: row;
}
.dialog--title {
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.0015em;
	text-align: left;
	padding-left: 16px;
}
.dialog--subtitle {
	display: block;
	width: 100%;
	font-size: 12px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0.004em;
	text-align: left;
	padding-left: 16px;
}
.dialog--name {
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0.0015em;
	text-align: left;
	padding-left: 16px;
}
.card--text {
	padding: 0 16px;
	font-size: 12px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0.004em;
}
</style>
