<template>
	<v-app>
    <rex-document></rex-document>
		<router-view v-if="pageMode === PageModeType.OK" :key="$route.meta.key || $route.name"/>
		<frp-page-not-found v-else-if="pageMode === PageModeType.PAGE_NOT_FOUND"></frp-page-not-found>
		<frp-access-denied-error v-else-if="pageMode === PageModeType.ACCESS_DENIED"></frp-access-denied-error>
	</v-app>
</template>

<script>
import { PageModeType } from "Store/rex/types/pageModeType";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import FrpAccessDeniedError from "Views/errors/FrpAccessDeniedError";
import RexDocument from "@/views/rex/views/RexDocument/RexDocument.vue";

export default {
	name: "RexLayout",
	data() {
		return {
			PageModeType, 
			pageMode: "",
		};
	},
	components: {
		RexDocument,
		FrpPageNotFound,
		FrpAccessDeniedError
	}
};
</script>

<style scoped lang="scss">

</style>
