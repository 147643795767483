import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { mocks } from "@/api/client/mock";
import { ApiTranchesUser } from "@/api/tranches/types/apiTranchesUser";
import { ApiProjectInfo } from "@/api/tranches/types/apiProjectInfo";
import { ApiTrancheSchedule } from "@/api/tranches/types/apiTrancheSchedule";
import { ApiTrancheScheduleTranche } from "@/api/tranches/types/apiTrancheScheduleTranche";
import { is } from "date-fns/locale";
import { ApiTrancheOrderCondition } from "@/api/tranches/types/apiTrancheOrderCondition";

export class TranchesController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getPermissions = async (): Promise<string[]> => {
		// console.warn("get: /permissions", "Получены permissions администратора", mocks.tranches.permissions.admin);
		// return mocks.tranches.permissions.admin;
		
		// console.warn("get: /permissions", "Получены permissions менеджера проекта", mocks.tranches.permissions.projectManager);
		// return mocks.tranches.permissions.projectManager;
		
		// console.warn("get: /permissions", "Получены permissions заявителя", mocks.tranches.permissions.applicant);
		// return mocks.tranches.permissions.applicant;
		
		console.warn("get: /permissions", "Получены permissions сотрудника ДСП", mocks.tranches.permissions.dsp);
		return mocks.tranches.permissions.dsp;
		
		return await this.client.get<string[]>(urls.tranches.permissions.get);
	};
	
	getCurrentUser = async (): Promise<ApiTranchesUser> => {
		console.warn("get: /me", "Получена текущий пользователь", mocks.tranches.tranchesUser);
		return mocks.tranches.tranchesUser;
		
		return await this.client.get<ApiTranchesUser>(urls.tranches.user.getCurrentUser);
	};
	
	getProjectInfo = async (loanApplicationNumber: string): Promise<ApiProjectInfo> => {
		await new Promise(resolve => setTimeout(resolve, 1000));
		console.warn(`get: /project/${loanApplicationNumber}`, "Получена информация о проекте", mocks.tranches.projectInfo);
		return mocks.tranches.projectInfo;
		
		const url = urls.tranches.project.getProjectInfo.replace(urlTemplateParts.id, loanApplicationNumber);
		return await this.client.get<ApiProjectInfo>(url, {});
	};
	
	//TODO: поправить при интеграции
	getTrancheSchedule = async (loanApplicationNumber: string, empty: boolean = false, isAccepted: boolean = false): Promise<ApiTrancheSchedule> => {
		await new Promise(resolve => setTimeout(resolve, 1000));
		if (empty) {
			console.warn(`get: /tranche-schedule/${loanApplicationNumber}`, "Получен график траншей", mocks.tranches.emptyTrancheSchedule);
			return mocks.tranches.emptyTrancheSchedule;
		} else if (isAccepted) {
			console.warn(`get: /tranche-schedule/${loanApplicationNumber}`, "Получен график траншей", mocks.tranches.trancheSchedule);
			return mocks.tranches.acceptedTrancheSchedule;
		} else {
			console.warn(`get: /tranche-schedule/${loanApplicationNumber}`, "Получен график траншей", mocks.tranches.trancheSchedule);
			return mocks.tranches.trancheSchedule;
		}
		
		const url = urls.tranches.trancheSchedule.getTrancheSchedule.replace(urlTemplateParts.id, loanApplicationNumber);
		return await this.client.get<ApiTrancheSchedule>(url, {});
	};
	
	updateTrancheSchedule = async (loanApplicationNumber: string, tranches: ApiTrancheScheduleTranche[]): Promise<void> => {
		await new Promise(resolve => setTimeout(resolve, 3000));
		console.warn(`post: /tranche-schedule/${loanApplicationNumber}`, "График траншей изменен");
		console.warn("Новые значения в графике:", tranches);
		return;
		
		const url = urls.tranches.trancheSchedule.updateTrancheSchedule.replace(urlTemplateParts.id, loanApplicationNumber);
		return await this.client.post(url, tranches);
	};
	
	updateTrancheScheduleOrderConditions = async (loanApplicationNumber: string, orderConditions: ApiTrancheOrderCondition[]): Promise<void> => {
		await new Promise(resolve => setTimeout(resolve, 3000));
		console.warn(`post: /tranche-schedule/${loanApplicationNumber}/order-condition`, "Условия выдачи графика траншей изменен");
		console.warn("Новые значения в графике в блоках условия выдачи:", orderConditions);
		return;
		
		const url = urls.tranches.trancheSchedule.updateTrancheScheduleOrderConditions.replace(urlTemplateParts.id, loanApplicationNumber);
		return await this.client.post(url, orderConditions);
	};
	
	sendTrancheScheduleForApproveByManager = async (loanApplicationNumber: string): Promise<void> => {
		await new Promise(resolve => setTimeout(resolve, 1000));
		console.warn(`post: /tranche-schedule/${loanApplicationNumber}/send`, "График траншей отправлен на утверждение менеджером");
		return;
		
		const url = urls.tranches.trancheSchedule.sendTrancheScheduleForApproveByManager.replace(urlTemplateParts.id, loanApplicationNumber);
		return await this.client.post(url, {});
	};
	
	withdrawTrancheSchedule = async (loanApplicationNumber: string): Promise<void> => {
		await new Promise(resolve => setTimeout(resolve, 3000));
		console.warn(`post: /tranche-schedule/${loanApplicationNumber}/withdraw`, "График траншей отозван заявителем");
		return;
		
		const url = urls.tranches.trancheSchedule.withdrawTrancheSchedule.replace(urlTemplateParts.id, loanApplicationNumber);
		return await this.client.post(url, {});
	};
	
	rejectTrancheSchedule = async (loanApplicationNumber: string): Promise<void> => {
		await new Promise(resolve => setTimeout(resolve, 3000));
		console.warn(`post: /tranche-schedule/${loanApplicationNumber}/reject`, "График траншей отклонен менеджером");
		return;
		
		const url = urls.tranches.trancheSchedule.rejectTrancheSchedule.replace(urlTemplateParts.id, loanApplicationNumber);
		return await this.client.post(url, {});
	};
	
	acceptTrancheSchedule = async (loanApplicationNumber: string): Promise<void> => {
		await new Promise(resolve => setTimeout(resolve, 3000));
		console.warn(`post: /tranche-schedule/${loanApplicationNumber}/accept`, "График траншей согласован менеджером");
		return;
		
		const url = urls.tranches.trancheSchedule.acceptTrancheSchedule.replace(urlTemplateParts.id, loanApplicationNumber);
		return await this.client.post(url, {});
	};
	
	orderTranchePaymentApplication = async (loanApplicationNumber: string, trancheNumber: string, orderCondition?: string): Promise<void> => {
		await new Promise(resolve => setTimeout(resolve, 1000));
		console.warn(`post: /tranche-schedule/${loanApplicationNumber}/order/${trancheNumber}`, `Отправлена заявка на платеж по номеру заявки: ${trancheNumber}${orderCondition ? (", и с условием выдачи " + orderCondition) : "."}`);
		return;
		
		const url = urls.tranches.trancheSchedule.orderTranchePaymentApplication.replace(urlTemplateParts.id, loanApplicationNumber).replace(urlTemplateParts.subId, trancheNumber);
		return await this.client.post(url, {});
	};
	
	generateTrancheSchedule = async (loanApplicationNumber: string): Promise<Blob> => {
		console.warn(`get: /tranche-schedule/${loanApplicationNumber}/file`, "Документ 'Информ-письмо с указанием разбивки суммы займа по траншам' сгенерирован");
		return new Blob(["hello-world"]);
		
		const url = urls.tranches.trancheSchedule.generateInformationalLetter.replace(urlTemplateParts.id, loanApplicationNumber);
		return await this.client.get<Blob>(url, { responseType: "blob" }, true);
	};
	
	generateTranchePaymentApplication = async (loanApplicationNumber: string, trancheNumber: string): Promise<Blob> => {
		console.warn(`get: /tranche-schedule/${loanApplicationNumber}/file/${trancheNumber}`, "Документ 'Заявка на осуществление платежа' сгенерирован");
		return new Blob();
		
		const url = urls.tranches.trancheSchedule.generatePaymentRequest.replace(urlTemplateParts.id, loanApplicationNumber).replace(urlTemplateParts.subId, trancheNumber);
		return await this.client.get<Blob>(url, { responseType: "blob" }, true);
	};
}
